export const FETCH_DRAFTS_LIST = '@/actual-sales-plan-draft/FETCH_DRAFTS_LIST';
export const SET_DRAFTS_LIST = '@/actual-sales-plan-draft/SET_DRAFTS_LIST';
export const GET_DRAFT_INFO = '@/actual-sales-plan-draft/GET_DRAFT_INFO';
export const SET_DRAFT_INFO = '@/actual-sales-plan-draft/SET_DRAFT_INFO';
export const CLEAR_CURRENT = '@/actual-sales-plan-draft/CLEAR_CURRENT';
export const UPDATE_DRAFT = '@/actual-sales-plan-draft/UPDATE_DRAFT';
export const DELETE_DRAFT = '@/actual-sales-plan-draft/DELETE_DRAFT';
export const MASS_DELETE_DRAFTS = '@/actual-sales-plan-draft/MASS_DELETE_DRAFTS';
export const MASS_VERIFY_DRAFTS = '@/actual-sales-plan-draft/MASS_VERIFY_DRAFTS';
export const RESTORE_DRAFT = '@/actual-sales-plan-draft/RESTORE_DRAFT';
export const VERIFY_DRAFT = '@/actual-sales-plan-draft/VERIFY_DRAFT';
export const SET_FILTER = '@/actual-sales-plan-draft/SET_FILTER';
export const SET_ACTIVE_FILTER = '@/actual-sales-plan-draft/SET_ACTIVE_FILTER';
