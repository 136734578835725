import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Select, Form } from 'antd';
import t from '@/utilities/translate';
import { triggers } from '@/redux/potential-sales/actions';
import DispatchOnMount from '@/components/common/DispatchOnMount';

const PotentialSalesAvailableManagers = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		id,
		changeManager,
		availableManagers,
		fetchAvailableManagers,
	} = props;
	const [opt, setOpt] = useState({ manager: null });
	const closeModal = () => {
		closeModalFn();
		changeManager({ id, managerId: opt.manager.id });
	};

	return (
		<Modal {...modalProps} onOk={closeModal} okText={t('deal/availableManagers/change')}>
			<DispatchOnMount actionCreator={() => fetchAvailableManagers(id)}>
				<Form.Item label={t('potential-sales/availableManagers/label')} style={{ padding: '20px 0 0 0' }}>
					<Select style={{ width: '100%' }}>
						{availableManagers.map((manager) => (
							<Select.Option
								key={manager.id}
								onClick={() => setOpt({ manager })}
							>
								{manager.name + ' ' + manager.last_name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</DispatchOnMount>
		</Modal>
	);
};

PotentialSalesAvailableManagers.defaultProps = {
	modalProps: {},
	availableManagers: [],
};

PotentialSalesAvailableManagers.propTypes = {
	modalProps: PropTypes.any,
	changeManager: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	id: PropTypes.number.isRequired,
	availableManagers: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
	})),
};


export default connect(
	(state) => ({
		availableManagers: state.potentialSales.get('availableManagers'),
	}),
	{
		...triggers,
	},
)(PotentialSalesAvailableManagers);
